































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import FlagshipGoalsModalViewModel
  from '@/vue-app/view-models/components/flagship/flagship-goals/flagship-goals-modal-view-model';

@Component({
  name: 'FlagshipGoalsModal',
  components: {
    FlagshipGoalsStepInit: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsStepInit.vue'),
    FlagshipGoalsStepShortGoal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsStepShortGoal.vue'),
    FlagshipGoalsStepMediumGoal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsStepMediumGoal.vue'),
    FlagshipGoalsStepLongGoal: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsStepLongGoal.vue'),
    FlagshipGoalsStepIndicator: () => import('@/vue-app/components/flagship/flagship-goals/FlagshipGoalsStepIndicator.vue'),
  },
})
export default class FlagshipGoalsModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  flagship_modal_view_model = Vue.observable(new FlagshipGoalsModalViewModel(this));

  closeModal() {
    this.synced_is_open = false;
  }

  mounted() {
    this.flagship_modal_view_model.loadGoalDefinitionStep();
  }
}
